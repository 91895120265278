import axios from "axios";
const backend = process.env.REACT_APP_BACKEND;
export const inputResponse = async (user_id,assistant_id,thread_id,fileId,inputValue) => {
    // console.log("in utils---------",user_id,assistant_id,thread_id,fileId,inputValue)
    try{
        const payload = {
            user_id:user_id,
            assistant_id:assistant_id,
            thread_id:thread_id,
            input:inputValue,
            file_id:fileId
        }
        const response = await axios.post(`${backend}/response`,payload)
        console.log("aajd--------",response)
        console.log("----file_id-------",response.data.file_id)
        return {"messages":response.data.messages.reverse(),"thread_id":response.data.thread_id,"file_id":response.data.file_id,"response_tokens":response.data.response_tokens,"session_tokens":response.data.context_tokens,"total_tokens":response.data.total_tokens}
    }catch(error){
        console.log(error)
        return {"error": error.message
        };
    }
}
export const getmessages = async (thread_id)=>{
    const response = await axios.get(`${backend}/messages?thread_id=${thread_id}`)
    // console.log("-------------",response)
    return response
}