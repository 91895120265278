import "./input.css";
import { useState, useContext, useEffect } from "react";
import { ThreadContext } from "../context/createContext.jsx";
import { MdOutlineFileUpload, MdImage } from "react-icons/md"; // Added MdImage for image upload icon
import { fileupload } from "../../utils/fileUpload.js";
import { AiOutlineSend } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { MdOutlineFileDownload } from "react-icons/md";
import { inputResponse } from "../../utils/sendinput.js";
import { fileDownload } from "../../utils/fileDownload.js";
import { getEncoding } from 'js-tiktoken';
import axios from "axios";

const Input = () => {
    const backend = process.env.REACT_APP_BACKEND; 
    const [downloadFile, setDownloadFile] = useState(true);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [hashSuggestions, setHashSuggestions] = useState(false);
    const [showHashSuggestions, setshowHashSuggestions] = useState([]);
    const [annotation, setAnnotation] = useState();
    const [tokens, setTokens] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);

    const {
        thread_id,
        setThreadId,
        assistant_id,
        user_id,
        setDisplayMessage,
        newThread,
        fileId,
        setFileId,
        file_id,
        setDownloadFileID,
        setStage,
        load,
        setLoad,
        inputValue,
        setInputValue,
    } = useContext(ThreadContext);

    useEffect(() => {
        const enc = getEncoding("gpt2");
        const encodedText = enc.encode(inputValue);
        setTokens(encodedText.length);
        console.log("Number of tokens:", encodedText.length);
    }, [inputValue]);

    async function handleSendMessage() {
        setLoad(false);
        setStage(0);
        setTimeout(() => {
            setStage(1); 
        }, 4000);
        setTimeout(() => {
            setStage(2); 
        }, 8000);
        
        const response = await inputResponse(user_id, assistant_id, thread_id, fileId, inputValue);
        console.log("--------in input---------", response);
        
        if (response.error) {
            alert(response.error);
            setInputValue("");
            setLoad(true);
        } else {
            setDisplayMessage(response.messages);
            setDownloadFileID(response.file_id);
            setThreadId(response.thread_id);
            setInputValue("");
            setLoad(true);
            setStage();
            if (response.file_id) {
                setDownloadFile(true);
            }
            alert(`sessionTokens:${response.session_tokens}, total Tokens: ${response.total_tokens}, ResponseTokens: ${response.response_tokens}`);
        }
    }

    const handleImageUpload = async (e) => {
        console.log("handleImageUpload fun")
        const uploadedFile = e.target.files[0];
        setUploadedImage(URL.createObjectURL(uploadedFile)); // For preview purposes
        console.log("uploadedImage",uploadedImage)
        const formData = new FormData();
        formData.append("image", uploadedFile);
        console.log("formData",formData)

        try {
            console.log("in try of handleimageupload")
            const response = await axios.post(`${backend}/imageUpload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("response----",response)

            if (response.status === 200) {
                alert("Image uploaded successfully");
            } else {
                alert("Error uploading image");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Error uploading image");
        }
    };

    const handleFileUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        const response = await fileupload(uploadedFile);
        if (response.data.file_id) {
            setFileId(response.data.file_id);
            alert("File uploaded successfully");
        } else {
            alert("Error in uploading a file");
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (inputValue === "") {
                alert("Please enter a valid input value");
            } else {
                handleSendMessage();
            }
        }
    };

    const handleFileDownload = async (e) => {
        e.preventDefault();
        if (file_id !== "") {
            const response = await fileDownload(file_id);
            const contentType = response.headers["content-type"];
            const contentTypeToExtension = {
                "application/xml": "xml",
                "application/msword": "doc",
                "application/xml; charset=utf-8": "xml",
            };

            const fileExtension = contentTypeToExtension[contentType] || "txt";
            const blob = new Blob([response.data]);
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `downloaded_file.${fileExtension}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } else {
            alert("File is not generated");
        }
    };

    const handleInputChange = async (e) => {
        const newInputValue = e.target.value;
        setInputValue(newInputValue);

        if (newInputValue.includes("@")) {
            const response = await axios.get(`${backend}/getAllAnnotations?assistant_id=${assistant_id}`);
            setSuggestions(response.data.Annotations);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }

        if (newInputValue.includes("#")) {
            const response = await axios.get(`${backend}/getAllParameters?annotations_name=${annotation}`);
            setshowHashSuggestions(response.data.Parameters);
            setHashSuggestions(true);
        } else {
            setHashSuggestions(false);
        }

        const textarea = e.target;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight > 70 ? "70px" : `${textarea.scrollHeight}px`;
    };

    const handleSuggestionClick = (suggestion) => {
        const replace_input = suggestion.replace("@", "");
        setInputValue(` ${replace_input} `);
        setShowSuggestions(false);
        setAnnotation(suggestion);
        setSuggestions([]);
    };

    const handleHashSuggestionClick = (suggestion) => {
        const replace_input = inputValue.replace("#", "");
        setInputValue(`${replace_input} ${suggestion.replace("#", "")} `);
        setHashSuggestions(false);
        setshowHashSuggestions([]);
    };

    return (
        <div className="chatbox_footer">
            <div className="chatbox_file">
                <label htmlFor="fileUpload" className="file-upload-label">
                    <span>
                        <MdOutlineFileUpload size={22} style={{ color: "#ccafeb" }} />
                        <input
                            type="file"
                            id="fileUpload"
                            name="uploadedFile"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                        />
                    </span>
                </label>
                <label htmlFor="imageUpload" className="image-upload-label">
                    <span>
                        <MdImage size={22} style={{ color: "#ccafeb" }} /> {/* Changed to MdImage for image upload */}
                        <input
                            type="file"
                            id="imageUpload"
                            name="uploadedImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleImageUpload}
                        />
                    </span>
                </label>
            </div>
            <div className="chatbox_input" style={{ position: "relative" }}>
                <textarea
                    type="text"
                    placeholder={
                        newThread || thread_id
                            ? "Type your message..."
                            : "Click on a new chat..../Prev Sessions"
                    }
                    onChange={handleInputChange}
                    value={inputValue}
                    disabled={!thread_id && !newThread}
                    onKeyDown={handleKeyPress}
                />
                <div className="prompt_tokens">{tokens}<span style={{ fontSize: "12px" }}> tokens</span></div>
                {showSuggestions && (
                    <div className="drop-up">
                        {suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className="suggestion-item"
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
                {hashSuggestions && (
                    <div className="drop-up">
                        {showHashSuggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                onClick={() => handleHashSuggestionClick(suggestion)}
                                className="suggestion-item"
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="chatbox_send">
                <button
                    className="send__button"
                    onClick={handleSendMessage}
                    disabled={!inputValue}
                >
                    {load ? (
                        <AiOutlineSend size={18} style={{ color: "#ccafeb" }} />
                    ) : (
                        <ClipLoader
                            color="white"
                            size={24}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    )}
                </button>
            </div>
            <div className="file_download">
                <MdOutlineFileDownload
                    size={22}
                    style={{ color: "#ccafeb" }}
                    onClick={downloadFile ? handleFileDownload : null}
                />
            </div>
        </div>
    );
};

export default Input;
