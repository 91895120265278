import "./content.css"
import Mainsession from "../sessions/sessions.jsx"
import { useContext, useEffect, useRef } from "react"
import Input from "../input/input"
import Header from "../header/header"
import { ThreadContext } from "../context/createContext.jsx";
import { getAllthreads } from "../../utils/getAllThreads.js";
import { FaUser } from "react-icons/fa";
import mobiusInfinity from "../../images/mobius-brand.svg"
import { getmessages } from "../../utils/sendinput.js"
// import { useState } from "react"
// import data from "../../files/suggestions.json"
const Content = () => {
  const { sessions, threadids, thread_id, setDisplayMessage,setDownloadFileID, load, stage,inputValue, displayMessage, setThreadIds, assistant_id, user_id, deleteThread, max } = useContext(ThreadContext);
  const chatContainerRef = useRef(null);
  // const [sugState,setSugState] = useState(0)
  // console.log("------prompt",inputValue)
  // console.log("json data",data)
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getmessages(thread_id)
        setDisplayMessage(response.data.messages.reverse())
        setDownloadFileID(response.data.file_id)  
      }
      catch (error) {
        // console.log(error)
      }
    }
    if (thread_id) {
      fetchData()
    }
  }, [thread_id, sessions, setDisplayMessage,setDownloadFileID]);
  // useEffect(()=>{
  
  //   if(assistant_id==="asst_Y2qfHgzZF0jcsOtlZMnOsEns"){
  //     console.log("pigpt suggestions",data.PIGPT["DeleteSchema"])
  //     setSugState(1)
  //   }else{
  //     setSugState(0)

  //   }

  // },[assistant_id])
  useEffect(() => {  // to get all threads for particular user and assistant
    async function fetchSession() {
      // console.log("in getall-------",thread_id)
      if (assistant_id) {
        await getAllthreads(user_id, assistant_id)
          .then(async (response) => {
            // console.log(response);
            setThreadIds([...response]);
            setDisplayMessage([]);
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    }
    fetchSession()

  }, [assistant_id, deleteThread, setDisplayMessage, setThreadIds, user_id, thread_id]);
  useEffect(() => {   // to make scrolling to move down 
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [displayMessage,load]);
  // const [selectedKey, setSelectedKey] = useState(null);

  // const handleClick = (key) => {
  //   setSelectedKey(key);
  // };
  return (
    <>
      <div className={` ${sessions ? "main_chat" : "chatbox"} `} >
      
        <div className="chat_header"><Header /></div>
        <div className="chats">
          {sessions ? <div className={` ${max ? "max_sessions" : "sessions"} `}><Mainsession data={threadids} /></div> : ""}
          <div className="messages">
              {/* <div className="suggestions">{!sessions && sugState===1?(
          Object.keys(data.PIGPT).map((key)=>{
            return     <div key={key}>
            <button onClick={() => handleClick(key)}>{key}</button>
            {selectedKey === key && (
              <div >
                {Object.keys(data.PIGPT[key]).map((childKey) => (
                  <div key={childKey}>
                    {childKey}: {JSON.stringify(data.PIGPT[key][childKey])}
                  </div>
                ))}
              </div>
            )}
          </div>
          })
        ):""}</div> */}
            <div className="chat_messages" ref={chatContainerRef}>
              
              <div className="text">
                {displayMessage && displayMessage.map((data, index) => (
                  <div key={index} >
                    {data.role === "user" ? (
                      <>
                        <div className="user_message" >
                        <div className="message right" >{data.content}     
                          </div>
                          <div className="user_image" > <FaUser /></div>
                          <div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="bot_message">
                          <div><img className="bot_image" src={mobiusInfinity} alt="mobiusImage"></img></div>
                          <div className="message left" >
                            {data.content}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            {!load && ( <div className="user_message" >
                          <div className="message right" >{inputValue}</div>
                          <div className="user_image" > <FaUser /></div>
                          <div>
                          </div>
                        </div>)}
                {!load && stage === 0 && (
                  <div className="bot_message ">
                  <div><img className="bot_image" src={mobiusInfinity} alt="mobiusImage"></img></div>
                  <div className="message left status_check" >
                  <strong className="fading">Input has been taken...</strong>
                  </div>
                </div>
                )}
                {!load && stage === 1 && (
                  <div className="bot_message">
                  <div><img className="bot_image" src={mobiusInfinity} alt="mobiusImage"></img></div>
                  <div className="message left status_check" >
                  <strong className="fading">  Data Being Analysed...</strong>
                  </div>
                </div>
                )}
                {!load && stage === 2 && (
                  <div className="bot_message ">
                  <div><img className="bot_image" src={mobiusInfinity} alt="mobiusImage"></img></div>
                  <div className="message left status_check" >
                  <strong className="fading">In Progress...</strong>
                  </div>
                </div>
                )}


              </div>
            </div>
            <div className="chat_input"><Input /></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Content