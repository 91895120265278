import React, {useState } from "react";

const ThreadContext=React.createContext();
const ThreadProvider=(props)=>{
    
    const [thread_id,setThreadId]=useState("");
    const [assistant_id,setAssistant_id] = useState("")
    const [user_id,setUserId] = useState("xyz");
    const [deleteThread,setDeleteThread] = useState(false);
    const [display, setDisplay] = useState(false);
    const [displayMessage, setDisplayMessage] = useState([]); // to display messages in chat box
    const [threadids, setThreadIds] = useState([])
    const [newThread, setNewThread] = useState(false); // activated when user clicks on new chat
    const [max, setMax] = useState(false);
    const [sessions, showSessions] = useState(false);
    const [fileId, setFileId] = useState("")
    const [file_id,setDownloadFileID] = useState("")
    const [inputValue,setInputValue] = useState("")
    const [stage, setStage] = useState();
    const [load, setLoad] = useState(true); // to set spinner in send message
    return(
        <ThreadContext.Provider value={{stage, setStage,thread_id,setThreadId,load, setLoad,inputValue,setInputValue,assistant_id,user_id,setUserId,setAssistant_id,deleteThread,setDeleteThread,display,displayMessage,threadids,setDisplay,setDisplayMessage,setThreadIds,newThread, setNewThread,max,setMax,sessions, showSessions,fileId, setFileId,file_id,setDownloadFileID}}>
            {props.children}
        </ThreadContext.Provider>
    )
}
export {ThreadContext,ThreadProvider}