import React from 'react';
import Content from "./chatmessages/content.jsx";
import "./chatbot.css";
import { useContext } from 'react';
import { FaRobot } from "react-icons/fa6";
import { ThreadContext } from './context/createContext.jsx';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
const Chatbot = () => {
    const { display, setDisplay,displayMessage} = useContext(ThreadContext);
    const [tokensUsed,setTokensUsed] = useState("")
    const [cost,setCost] = useState("")
    const [tokensLeft,setTokensLeft] = useState("")
    const { max } = useContext(ThreadContext);
    const toggleDisplay = () => { // to show sessions
        setDisplay((prevDisplay) => !prevDisplay);
    };
    const backend = process.env.REACT_APP_BACKEND; 
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    useEffect(()=>{
        async function fetchdata(){
            const response = await axios.get(`${backend}/token?user_id=xyz`)
            console.log(response)
            setTokensUsed(response.data.tokens)
            setCost(response.data.cost)
            const tokensleft = 126051-response.data.tokens
            setTokensLeft(tokensleft)
        }
        fetchdata()
    },[displayMessage])// eslint-disable-line 
    
    return (
        <>
        <div className='logout_bt'><button onClick={handleLogout}>Logout</button></div>
            <div className='token_info'>
                <div className='t_info'>Tokens Used<span style={{marginLeft:"2px",marginRight:"2px"}}>:</span>{tokensUsed} <span style={{fontSize:"x-small",marginLeft:"2px"}}> per month</span></div>
                <div className='t_info'>Tokens Left<span style={{marginLeft:"10px",marginRight:"2px"}}>:</span> {tokensLeft} <span style={{fontSize:"x-small",marginLeft:"2px"}}> per month</span></div>
                <div className='t_info'>Usage Cost<span style={{marginLeft:"10px",marginRight:"2px"}}>:</span>{cost} <span style={{fontSize:"x-small",marginLeft:"2px"}}> per month</span></div>
            </div>
            {display ? (
                <div className={` ${max ? "max_chats" : "main_chatbot"}`}>
                    <Content />
                </div>)
                : (
                    <div className="chatbox__button" >
                        <button onClick={() => toggleDisplay()}>
                            <FaRobot size={30} />
                        </button>
                    </div>
                )
            }

        </>
    );
};

export default Chatbot;
