import { useState, useContext, useEffect } from "react";
import { MdFormatListBulleted } from "react-icons/md";
import { HiOutlinePlusCircle } from "react-icons/hi";
import mobiusInfinity from "../../images/mobius-brand.svg"
import { FiMinimize2 } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import { LuMoveDiagonal } from "react-icons/lu";
import { ThreadContext } from "../context/createContext.jsx";
import "./header.css"
import { getAllAssistant } from "../../utils/allAssistants.js";
// import Content from "../chatmessages/content.jsx";
const Header = () => {
    const [assistantName, setAssistantName] = useState("")
    const [assistants, setAssistants] = useState([])
    let assistant_name;
    const {
        setThreadId,
        assistant_id,
        user_id,
        setAssistant_id,
        setDisplay,
        setDisplayMessage,
        setThreadIds,
        setNewThread,
        max, setMax,
        showSessions

    } = useContext(ThreadContext);

    useEffect(() => {   //to get all the assistants from backend
        getAllAssistant(user_id)
            .then(async (response) => {
                // console.log(response);
                setAssistants([...response]);
                // console.log("assistant in app", assistants);
            })
            .catch((e) => {
                // console.log(e);
            });
    }, [user_id]);
    const handleMaximize = () => {
        setMax((prevDisplay) => !prevDisplay);
    };
    const handleClose = () => { //functions to be performed when closed the chat
        // console.log("display in header in close click", display)
        setDisplay(false);
        setDisplayMessage([]);
        setThreadIds([]);
        showSessions(false);
        setAssistant_id("");
        setThreadId();
        setAssistantName("")
        // console.log("display in header---", display)
    };
    const handleAssistantChange = async (event) => {  // to selectassistant from dropdown
        const selectedAssistantId = event.target.value;
        await setAssistant_id(selectedAssistantId);
        // console.log("Selected Assistant ID:", selectedAssistantId);
        // Fetch the corresponding assistant name using the selectedId
        const selectedAssistant = assistants.find((assistant) => assistant.assistant_id === selectedAssistantId);
        assistant_name = selectedAssistant.assistant_name
        setAssistantName(assistant_name)
        // console.log(assistant_name)
    };
    const handleNewSession =async () => {
        // console.log("newthread initial----", thread_id);
        if (!assistant_id) {
            alert("Please select Assistant");
        } else {
            setNewThread(true);
        }
        setDisplayMessage([]);
        await setThreadId()
        
    };
    // console.log("newthread final --------", thread_id);
    return (
        <>
            <div className="header_section">
                <div>
                    <button
                        className="button_"
                        onClick={() => showSessions((prevDisplay) => !prevDisplay)}
                    >
                        <MdFormatListBulleted
                            title="Sessions"
                            color="grey"
                            size={15}
                        />
                    </button>
                </div>
                <div className="header"><img src={mobiusInfinity} className="header_image" alt="mobiusImage"/>{assistantName ? <div><strong>{assistantName}</strong></div> : <div><strong>MobiusGPT</strong></div>}</div>
                <div id="closeChat">
                    {/* {" "} */}
                    <div className="max_button">{max ? (
                        <FiMinimize2
                            title="min"
                            size={12}
                            onClick={handleMaximize}
                        />
                    ) : (
                        <LuMoveDiagonal
                            title="max"
                            size={14}
                            onClick={handleMaximize}
                        />
                    )}
                    </div>
                    <div className="close_chat">
                        <MdClear onClick={handleClose} title="close" size={15} />
                    </div>
                </div>
            </div>
            <div className="assis">
                <div className="chat_subheader" onClick={handleNewSession}>
                    <div className="chat_subheader_section">
                        <div><HiOutlinePlusCircle
                            title="add New Chat"
                            style={{ cursor: "pointer" }}
                            size={22}
                        />
                        </div>
                        <div style={{ fontSize: "0.75rem", cursor: "pointer" }}>New Chat</div>
                    </div>

                </div>
                <label htmlFor="chatDropdown"></label>
                <select
                    id="chatDropdown"
                    className="assistant_select"
                    onChange={(e) => {
                        handleAssistantChange(e);
                    }}
                >
                    <option value="" disabled selected>
                        Select Assistant
                    </option>
                    {assistants.map((assistant) => (
                        <option
                            key={assistant.assistant_id}
                            value={assistant.assistant_id}
                        >
                            {assistant.assistant_name}
                        </option>
                    ))}
                </select>
            </div>
            {/* <Content props = {assistantName}/> */}
        </>

    )
}
export default Header